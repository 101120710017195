.ant-input {
  width: 100%;
  border-radius: 4px;

  &::placeholder {
    color: $color-app-gray1;
  }

}

.ant-input-lg,
.ant-input-affix-wrapper-lg,
.ant-picker-large {
  padding: 8px 16px;
  border-radius: var(--border-radius);

  >.ant-input-lg {
    border-radius: unset;
  }

}

.ant-select.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 43.78px;
  border-radius: var(--border-radius);
  padding: 0 16px;

  .ant-select-selection-search {
    left: 16px;
  }

  input {
    height: 41.78px !important;
  }
}

.ant-input-number-lg {
  border-radius: var(--border-radius);
  height: 43.78px;
}

.ant-input-number-lg input {
  padding: 0 16px;
  height: 41.78px;
}

.ant-input-number-affix-wrapper {
  padding: 0 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  border-color: #C0C0C0;
}

.ant-select-clear,
.ant-select-arrow {
  right: 16px;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  border-radius: 8px;
}

.ant-picker-range .ant-picker-clear {
  right: 16px;
}

.ant-input-prefix,
.ant-input-suffix,
.ant-input-number-prefix {
  color: #7D7E7E;
}

.input-number-with-sufix {
  &.ant-input-number-affix-wrapper {
    display: flex;
    flex-direction: row-reverse;
    border-radius: var(--border-radius);
  }

  .ant-input-number-lg {
    height: calc(43.78px - 2px);
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-prefix {
    margin: 0;
  }
}