@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-secondary: #7d7e7e;
  --border-radius: 8px;
}

.root {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
