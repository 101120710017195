/* Color*/
$color-base: #666;
$color-secondary: #222;
$color-text: #666;
$color-text-secondary: #888;
$color-text-heading: #222;
$color-link: #3498db;
$color-bg-footer: #282828;

/* Variable Template, App Layout */

$color-app-base: #1a1a1a;
$color-app-primary: #2a9f9c;
$color-app-primary-m: #22817e;
$color-app-tertiary: #e2fbfa;
$color-app-secondary: #f4f6f8;
$color-app-border: #34c4c0;
$color-app-disabled: #bdbdbd;
$color-app-base-input: #d9d9d9;
$color-app-link: #008df9;
$color-app-modal-border: #c7c7c7;
$color-app-placeholder: #bdbdbd;
$color-app-heading-sub: #707070;
$color-app-white: #fff;
$color-app-gray: #C7C7C7;
$color-app-gray1: #BDBDBD;
$color-app-gray2: #707070;
$color-app-gray3: #EBEBEB;
$color-app-gray4: #F5F5F5;
$color-app-gray5: #E5E5E5;
$color-app-orange: #FFA634;
$color-app-red: #FF4559;
$color-app-6: #A6A6A6;
$color-app-blue: #4674C1;
$color-app-orange-1: #F4B171;
$color-app-black: #2b2424;
$color-app-pink: #FFE4E6;
//**************************

$darkSkyBlue: #3498db;
$line: #cbcacf;
$title: #333;
$jadeGreen: #27ae60;
$brownishGrey: #666;
$bloodOrange: #ff4400;
$tomato: #e74c3c;
$btn: #adadad;

/*//Fonts-size*/
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 32px;

/*//Url base*/
$url-img: '../../images';

/*//Breakpoint*/
$bp-xs: 320px;
$bp-mobile: 320px;

$bp-s: 480px;
$bp-sm: 768px;
$bp-tablet: 768px;

$bp-m: 992px;
$bp-l: 1200px;
$bp-xl: 1440px;

/*// Contain floats / clearfix*/

%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
}

/* New style 2018-03-08 */

/* Variable Template, App Layout */
$color-app-base: #1a1a1a;
$color-app-primary: #2a9f9c;
$color-app-primary-m: #22817e;
$color-app-tertiary: #e2fbfa;
$color-app-secondary: #f4f6f8;
$color-app-border: #34c4c0;
$color-app-disabled: #bdbdbd;
$color-app-base-input: #d9d9d9;
$color-app-link: #008df9;
$color-app-modal-border: #c7c7c7;
$color-app-placeholder: #bdbdbd;
$color-app-heading-sub: #707070;
$color-app-violet: #CD5E77;
$color-app-violet-light: #FFF7F7;
$color-app-violet-light1: #f4dcdf;
$color-app-violet-light2: #FFE4E6;
$color-app-violet-light3: #EBA7AC;
$base-color: #00abf3;
$base-text-color: #fff;
$color-app-green: #09B8AF;

$font-weights: (
    'thin': 100,
    'light': 300,
    'regular': 400,
    'medium': 500,
);

@function font-weights($style) {
    @return map-get($font-weights, $style);
}

$app-font-sizes: (
    'sm': 12px,
    'reg': 14px,
    'md': 16px,
    'lg': 24px,
    'heading': 20px,
);

@function app-font-sizes($style) {
    @return map-get($app-font-sizes, $style);
}

$app-button-colors: (
    'selected': $color-app-tertiary,
    'clicked': $color-app-secondary,
    'hover': $color-app-primary-m,
);

@function app-button-colors($style) {
    @return map-get($app-button-colors, $style);
}

$app-text-colors: (
    'base': $color-app-base,
    'primary': $color-app-primary,
    'secondary': $color-app-placeholder,
    'desc': $color-app-heading-sub,
    'badge': $color-app-tertiary,
);

@function app-text-colors($style) {
    @return map-get($app-text-colors, $style);
}
$primary-color: #CD5E77;
$primary-color-hover: #FFD3D8;
$menu-item-parent-color: #BDBDBD;
$box-shadow-color: #ced1f3;
$box-shadow-color1: #E6E6E6;
$box-shadow-color: #ced1f3;


//table 
$table-header-bg: #F2F2F2;
$bg-color-gray-tab: #F4F6F8;


//Font Family
$robotofont: 'Roboto', sans-serif;

//Font Size
$txt-size-h1: 24px;
$txt-size-h2: 22px;
$txt-size-h3: 20px;
$txt-size-h4: 18px;
$txt-size-h5: 16px;
$txt-size-h6: 15px;
$txt-size-h7: 14px;
$txt-size-h8: 12px;
$txt-size-h9: 10px;
$txt-size-32: 32px;
//Color
$txt-color-white: #ffffff;
$txt-color-black2: #333333;
$txt-color-black3:#363636;
$txt-color-black: #1a1a1a;
$txt-color-secondary: #707070;
$txt-color-gray: rgba(0, 0, 0, 0.45);
$txt-color-gray2: #bdbdbd;
$txt-color-gray3: #f2f2f2;
$txt-color-gray4: #707070;
$txt-color-gray6: #C7C7C7;
$txt-color-gray7: #7D7E7E;;
$txt-color-gray5: #EBEBEB;
$txt-color-gray8: #5A5A5A;
$txt-color-red: #F5222D;
$txt-color-red2: #FF4559;
$txt-color-red3: #E63D4F;
$txt-color-red4: #BF3443;
$txt-color-orange: #F59F32;
$txt-color-orange1: #FBB739;
$txt-color-orange2: #FCD535;
$txt-color-orange3: #FAA200;
$txt-color-orange4: #FDB924;
$txt-color-orange5: #FFEECE;
$txt-color-orange6: #FCD535;
$txt-color-orange7: #FFF9DF;
$txt-color-blue: #096DD9;
$txt-color-blue1: #303a41;
$txt-color-blue2: #001A4B;
$txt-color-blue3: #0082E5;
$txt-color-blue5: #09B8AF;
$txt-color-blue6: #3340B6;
$txt-color-blue7: #008DF9;
$txt-color-blue8: #339DFF;
$txt-color-green: #09B2AA;
$txt-color-green1: #43B082;
$txt-color-only-staff: #0F98B1;
$txt-color-primary: #F59F32;
$txt-color-order-detail: #F9A400;
$txt-color-order-finance: #FFF7EE;
$txt-color-pink4: #F54255;

//Background Color
$bg-color-primary: #fcd535;
$bg-color-white: #ffffff;
$bg-color-yellow: #fffbe6;
$bg-color-blue: #096dd9;
$bg-color-blue2: #e6f7ff;
$bg-color-blue3: #a6dbff;
$bg-color-gray: #f0f2f5;
$bg-color-gray2: #D9D9D9;
$bg-color-gray3: #F2F2F2;
$bg-color-gray4: #707070;
$bg-color-gray7: #BDBDBD;
$bg-color-gray5: #EBEBEB;
$bg-color-gray6: #C7C7C7;
$bg-color-gray-badge: #F5F5F5;
$bg-color-gray-tab: #F4F6F8;
$bg-color-dark: #011d57;
$bg-color-green: #09b2aa;
$bg-color-black: #000000;
$bg-color-orange: #FFEDEF;
$bg-color-orange1: #FBB739;
$bg-color-orange2: #FFD7A5;
$bg-color-orange3: #FAA200;
$bg-color-orange4: #ffeece;
$bg-color-orange5: #FFF9DF;

$bg-color-blue4: #DDF0FF;
$bg-color-blue5: #09B8AF;
$bg-color-blue6: #E0F9F7;
$bg-color-blue7: #3340B6;
$bg-color-pink:#FFF7EE;
$bg-color-pink1:#FFEED9;
$bg-color-pink2:#FFF6E5;
$bg-color-pink4: #F54255;
$bg-color-pink5: #FFEDEF;
$bg-color-pink6: #FEEDDE;

/***** guide-line *****/

//border
$bd-disabled: #C7C7C7;
$bd-warning: #FF4559;
$txt-warning: #FF4559;
$bd-divider-success-box:#FFA600;
/***** guide-line *****/
// bg
$bg-warning: #FF4559;
$bg-disabled: #f5f5f5;
$bg-secondary-gray: #ECEDF8;

//box-shadow

$box-sd-primary: #ffe3c0;
$box-sd-secondary: #ced1f3;
$box-sd-warning: #FFB2BA;
$bg-color-gray8: #EDF3F7;
$bg-color-orange2: #FCD535;;

//Border Color
