.orders {
	line-height: 20px;
	padding: 12px;
	&-filter {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 12px;
		box-shadow: 0px 2px 14px #e6e6e6;
		background-color: white;
		.ant-checkbox + span {
			font-size: app-font-sizes(reg);
		}
		.ant-input-affix-wrapper {
			border-radius: 4px;
		}
		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border-radius: 4px;
		}
		&__title {
			gap: 8px;
			flex-wrap: nowrap;
			& .title {
				& > h3 {
					font-size: app-font-sizes(md);
					white-space: nowrap;
					margin: 0;
				}
				flex-basis: fit-content;
			}

			& .divider {
				& .ant-divider {
					border-top: 1px solid $color-app-gray3;
				}
				flex-basis: 100%;
			}
		}
		&__statuses {
			display: flex;
			flex-wrap: wrap;
			&-label {
				font-size: app-font-sizes(reg);
				margin-right: 4px;
				margin-bottom: 7px;
			}
			& .status {
				text-transform: capitalize;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $color-app-violet;
				font-size: app-font-sizes(sm);
				font-weight: 500;
				line-height: 20px;
				background-color: $color-app-violet-light;
				border-radius: 4px;
				padding: 2px 10px;
				margin-right: 6px;
				margin-bottom: 7px;
				cursor: pointer;
				&.active,
				&:hover {
					background-color: $color-app-violet;
					color: white;
				}
			}
		}
		&__form {
			&-input {
				margin-bottom: 12px;
				.ant-row {
					margin: 0;
				}
				input:not([type='range']) {
					border-radius: 4px;
				}
				input[type='range'] {
					border-radius: 2px;
				}
				input {
					width: 100%;
					&::placeholder {
						color: $color-app-gray1;
					}
				}
				& .form-label {
					font-size: app-font-sizes(reg);
					white-space: nowrap;
					line-height: 20px;
				}

				&--date {
					display: flex;
					align-items: center;
					gap: 10px;
					&.timestamp > .ant-form-item > .ant-form-item-label {
						flex-basis: 112px;
					}
					.ant-row {
						width: 100%;
						& .ant-picker {
							width: 100%;
							border-radius: 4px;
						}
					}
				}
				& .selection {
					flex-shrink: 0;
					& .ant-select {
						.ant-select-selection-item {
							line-height: 40px;
							display: flex;
							align-items: center;
							& .divider {
								border-left: 2px solid $color-app-gray2;
							}
						}
					}
				}
			}
		}
		.actions {
			display: flex;
			height: 32px;
			&-btn {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				& > button {
					border-radius: 4px;
				}
				& .clear {
					margin-right: 12px;
					color: $color-app-gray2;
					border: none;
					box-shadow: none;
					.anticon {
						transform: rotate(-69deg);
					}
					&:hover {
					}
				}
			}
		}
		.marketplaces {
			font-size: app-font-sizes(reg);
			& .ant-checkbox-group-item {
				margin-right: 30px;
			}
		}
	}
	&-data {
		.ant-pagination-item {
			font-family: 'Roboto', sans-serif;
			& > a {
				font-size: app-font-sizes(reg);
			}
			&-active {
				background-color: $color-app-violet;
				& > a {
					color: white;
				}
			}
		}
		.ant-pagination-options .ant-select-selection-item {
			font-size: app-font-sizes(reg);
			color: #707070;
		}
		.ant-table-cell {
			vertical-align: middle;
			border-bottom: 12px solid $color-app-violet-light;
			padding: 20px;
		}
		&__info {
			&-text {
				& .code {
					margin: 0 !important;
					font-weight: 500;
					white-space: nowrap;
					color: $color-app-violet;
					font-size: app-font-sizes(reg);
					line-height: 22px;
				}
				& .divider {
					width: 1px;
					height: 16px;
					background-color: $color-app-gray;
				}
			}
		}

		&__provider {
			line-height: 20px;
			flex-wrap: nowrap;
			& .logo {
				flex-shrink: none;
			}
			& .text {
				white-space: nowrap;
			}
		}

		&__status {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: app-font-sizes(reg);
			line-height: 20px;
			& > i {
				color: #cd5e77;
			}
			& > span {
				padding: 1px 10px;
				border-radius: 25px;
				color: white;
				white-space: nowrap;
			}

			&-button {
				margin-bottom: -24px;

				>span {
					text-decoration: underline;
				}
			}
		}
		.ant-pagination-total-text {
			margin-right: auto;
		}
	}
}

.txt-violet {
	color: $color-app-violet;
}

.orders-data__status-select {
	width: 100%;
	justify-content: center;

	.ant-select-arrow {
		color: #ffffff;
	}

	.ant-select-selector {
		border: 1px solid transparent !important;
		border-radius: 20px !important;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		text-align: center;
	}
	.ant-select-selection-item {
		color: #ffffff !important;
	}

	&.NOTIFIED {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #008DF9 !important;
		}
	}

	&.UNPROMOTED {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #7d7e7e !important;
		}
	}

	&.PROMOTED {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #09b8af !important;
		}
	}

	&__pending {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #ffa634 !important;
		}
	}

	&__paid {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #09b8af !important;
		}
	}

	&__rejected {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #ff4559 !important;
		}
	}

	&__waiting {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #7d7e7e !important;
		}
	}

	&__notified {
		.ant-select-selector {
			border-radius: 20px !important;
			color: #ffffff;
			background-color: #3340B6 !important;
		}
	}
}

.package-order-list {
  .package-order-header {
    padding: 15px;
    //margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
		justify-content: space-between;
		align-items: center;

		.info-container {
			max-width: 40%;
			}

			button {
				max-width: 55%;
			}

    &__title {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 16px;
    }

    &__badge {
      margin: 0 0 0 5px;
      display: block;
      padding: 0 8px;
      color: #ffffff;
      background: #CD5E77;
      border-radius: 20px;
      line-height: 2;
			max-height: 24px;
    }

  }

		&__badge {
			margin: 0 0 0 5px;
			display: block;
			padding: 0 8px;
			color: #ffffff;
			background: #cd5e77;
			border-radius: 20px;
			line-height: 2;
		}
}

.order-payment__preview-modal {
	.ant-modal-header {
		width: 100%;
		align-items: center;
		text-align: center;
		font-family: Roboto, sans-serif;
	}
	&_main {
		font-weight: 500;
		font-size: 16px;
	}
	&_sub {
		font-weight: 400;
		font-size: 14px;
	}
	.ant-modal-body {
		max-height: 600px;
		div {
			text-align: center;
			img {
				//width: 100%;
				max-height: 560px;
				max-width: 480px;
			}
		}
	}
}

.order-payment__request-bill-modal {
	.ant-radio-group {
		width: 100%;
    display: flex;
    justify-content: center;
	}

	&__header {
		border-bottom: 1px solid #ebebeb;
    line-height: .1em;
    margin: 10px 0 20px;
    text-transform: capitalize;
    width: calc(100% - 22px);

		> span {
			background: #fff;
			padding-right: 12px;
			font-weight: 500;
			font-size: 16px;
		}
	}

	.ant-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
		label, input {
			font-size: 14px;
		}

		.ant-form-item {
			margin-bottom: 12px;
		}

		.ant-form-item-explain {
			min-height: 0;
		}
	}

	&__buttons {
		margin-top: 10px;
		display: flex;
    justify-content: flex-end;
	}
}

@media screen and (max-width: 1200px) {
	.orders-filter__form {
		&.ant-form-horizontal .ant-form-item-label {
			flex-basis: 105px;
		}
	}
}
//

@media screen and (min-width: 1176px) {
	.orders-filter {
		&__form {
			&.extends {
				& .timestamp, & .code {
					& .ant-form-item > .ant-form-item-label {
						flex-basis: 112px !important;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1175px) {
	.orders-filter {
		&__form {
			&.extends {
				& .email, & .code {
					& .ant-form-item > .ant-form-item-label {
						flex-basis: 112px !important;
					}
				}
				& .username, & .timestamp {
					& .ant-form-item > .ant-form-item-label {
						flex-basis: 100px !important;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 991px) {
	.orders-filter {
		&__form {
			&.extends {

				& .username {
					& .ant-form-item > .ant-form-item-label {
						flex-basis: auto !important;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 930px) {
	.orders-filter {
		&__form {
			&.extends {
				& .phone, & .code, & .timestamp {
					& .ant-form-item > .ant-form-item-label {
						flex-basis: 112px !important;
					}
				}
				& .username, & .email {
					& .ant-form-item > .ant-form-item-label {
						flex-basis: 58px !important;
					}
				}
			}
		}
	}
}
