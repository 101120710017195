.ant-modal-root {
	.ant-modal-content {
		border-radius: 6px;
	}

	.ant-modal-close-x {
		width: 48px;
		height: 48px;
		color: #1a1a1a;
	}

	.ant-modal-header {
		border-radius: 4px 4px 0 0;
		padding: 16px;
		padding-bottom: 8px;
		border-bottom: 0;
	}

	.ant-modal-body {
		padding: 0 16px;
		padding-top: 8px;
		max-height: 80vh;
		overflow: hidden auto;
	}

	.ant-modal-footer {
		padding: 16px;
	}
}
.ant-modal-wrap {
	overflow: hidden;
}
