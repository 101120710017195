.btn {
	color: $color-app-base;
	box-shadow: none;

	&.ant-btn:not([disabled]) {
		&:active {
			box-shadow: none;
		}
	}

	&-primary {
		&.ant-btn:not([disabled]) {
			color: #fff;
			border-radius: 4px;

			&:hover {
				box-shadow: 0 0 0 3px $color-app-violet-light1;
				background-color: $color-app-violet;
			}
		}
	}
}

.ant-btn {
	border-radius: 4px;
	display: inline-flex;
	align-items: center;
	gap: 8px;

	&:not(:disabled) {
		&.ant-btn-default {
			color: #d98697;
			border-color: #d98697;
		}
	}

	&.ant-btn-primary {
		&:disabled {
			border: 1px solid #c7c7c7;
		}
	}

	&.ant-btn-ghost {
		&:not([disabled]) {
			&:hover {
				background-color: #707070;
				color: #ffffff;
				border: 1px solid transparent;
			}
		}
	}

	&.ant-btn-background-ghost.ant-btn-primary:hover {
		color: #ffffff;
	}
}

.ant-btn-text {
	margin: 0;
	padding: 0;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
	background: #FFF;
	color: $primary-color;
}