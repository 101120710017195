.order {
	&-heading {
		&__basic {
			margin-right: 64px;
			&-image {
				height: 100%;
				width: 56px;
			}
			&-text {
				&--code {
					.ant-select:not(.ant-select-customize-input) .ant-select-selector {
						background-color: #3eb2f0;
						border-radius: 25px;
						height: 28px;
						align-items: center;
						color: white;
						border: none;
						box-shadow: none !important;
						& .ant-select-selection-item {
							font-weight: 500;
							font-size: 14px;
							line-height: 22px;
						}
					}
					.ant-select-single.ant-select-open .ant-select-selection-item {
						color: white;
					}
					.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
						border: none;
						box-shadow: none;
					}
				}
			}
		}
	}
	& .item {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		&-finance {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		& .name {
			width: 100%;
		}

		& .text {
			flex-basis: 100pc;
			&-sub {
				color: $color-app-gray1;
			}
		}
		& .ant-typography {
			margin-bottom: 0;
			&.editing {
				left: 0 !important;
				& > textarea {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}

	&-milestones {
			// flex-basis: 100%;
			display: flex;
			flex-direction: column;
			padding: 12px 12px 0 12px;
			box-shadow: 0px 2px 14px $box-shadow-color1;
			gap: 8px;
			background-color: white;
			border-radius: 4px;
			.ant-steps-item-active {
				.ant-steps-item-tail {
					&::after {
						background-color: $color-app-violet !important;
					}
				}
			}
			.ant-steps-item-wait {
				.ant-steps-item-tail {
					&::after {
						background-color: #bdbdbd !important;
					}
				}
			}

			& .ant-steps-item-icon {
				width: 10px !important;
				height: 10px !important;
				.ant-steps-icon {
					top: -10.5px !important;
				}
			}
			.ant-steps-item-content {
				text-align: left !important;
			}

			.ant-steps-item-tail {
				top: 1.5px !important;
				&::after {
					margin-left: 13.5px !important;
				}
			}

			.ant-steps-item-title {
				line-height: 20px !important;
				font-size: 12px !important;
				font-family: 'Roboto', sans-serif !important;
				color: #1a1a1a;
		}
	}

	.fees {
		&-calculate,
		&-summary {
			display: flex;
			flex-direction: column;
			gap: 4px;
			background-color: $color-app-violet-light2;
			font-size: app-font-sizes(reg);
			line-height: 22px;
			border-radius: 4px;
			& .ant-divider-horizontal {
				margin: 0;
				border-top: 1px solid $color-app-violet-light3;
			}
		}
		&-calculate {
			padding: 8px 10px 16px;
		}
		&-summary {
			padding: 8px 10px 6px;
			margin-top: 8px;
		}
	}

	&-content {
		display: flex;
		&__text {
			display: flex;
			flex-direction: column;
			flex-basis: calc((100% - 10px) * 8 / 10);
			flex-wrap: wrap;
			justify-content: flex-start;
			&-info,
			&-finance {
				// box-shadow: 0px 2px 14px $box-shadow-color1;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
			&-info {
				display: flex;
				flex-direction: column;
				flex-basis: 60%;
				margin-right: 10px;
				background-color: white;
				& .ant-divider-vertical {
					margin: 0 20px;
				}
				& > h3 {
					font-weight: 500;
					font-size: app-font-sizes(md);
					line-height: 24px;
					margin: 0;
				}
			}
			&-finance {
				flex-basis: 40%;
				& > h2 {
					font-size: app-font-sizes(md);
					line-height: 24px;
				}
			}
		}
		&__track {
			background-color: white;
			padding: 12px 12px 0 12px;
			box-shadow: 0px 2px 14px $box-shadow-color1;
			height: fit-content;
			flex-basis: calc((100% - 10px) * 2 / 10);
		}
	}

	&-tabs {
		box-shadow: 0px 2px 14px $box-shadow-color1;
		margin-top: 10px;
		padding: 12px;
		background-color: white;
		& .ant-tabs-nav {
			margin-bottom: 10px;
			& .ant-tabs-tab {
				padding-top: 0;
				padding-bottom: 0;
				&:not(:first-of-type) {
					margin-left: 12px;
				}
			}
		}

		& .item-link {
			font-size: app-font-sizes(reg);
			line-height: 22px;
			&:hover {
				color: $color-app-violet;
			}
		}
	}

	&-dropdown__status {
		// padding: 0;
		border-radius: 0 0 2px 2px;
		& .ant-select-item-option-content {
			font-size: app-font-sizes(reg);
			line-height: 22px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.order {
		&-heading,
		&-tabs,
		&-milestones,
		&-content__info,
		&-content__text-info,
		&-content__text-finance {
			max-width: calc(100vw - 24px);
		}
		&-milestones {
			flex-basis: 100%;
		}
		&-content {
			flex-wrap: wrap;
			&__info {
				flex-basis: 100%;
				margin-bottom: 12px;
				&-finance {
					flex-wrap: wrap;
				}
			}
			&__text-info {
				flex-basis: 100%;
				margin-bottom: 12px;
				margin-right: 0;
			}
			&__text-finance {
				flex-basis: 100%;
			}
		}
		&-milestones {
			.ant-steps {
				overflow-x: scroll;
				gap: 4px;
			}
			.ant-steps-item-tail {
				top: 1.5px !important;
				margin-left: 0;
				&::after {
					// margin-left: 0 !important;
				}
			}

			& .ant-steps-item {
				& .ant-steps-item-container {
					display: flex;
					flex-direction: column-reverse;
					justify-content: space-between;
					gap: 2px;
					height: 100%;
					& .ant-steps-item-description{
						margin-bottom: 8px;
					}
				}
			}

			.ant-steps-item-active .ant-steps-item-tail::after {
				background-color: #bdbdbd !important;
				border: 1px solid #c7c7c7;
				height: 2px;
			}

			.package-timeline .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail, .ant-steps-item.ant-steps-item-active .ant-steps-item-tail {
				bottom: 11px !important;
				top: unset !important; 
			}
		}
	}
}

@include xs {
	.order-heading {
		&__basic {
			flex-grow: 1;
			margin: 0;
			&-text {
				flex-direction: row;
				flex-basis: 100%;
				flex-wrap: wrap;
				&--code {
					gap: 10px;
					flex-wrap: wrap;
					margin-bottom: 6px;
					flex-basis: 100%;
					& .ant-typography {
						margin-right: 0;
						font-size: 14px;
						line-height: 22px;
					}
				}
			}
			&-merchant {
				flex-basis: 100%;
				flex-direction: row;
				flex-wrap: wrap;
				&--cash,
				&--name {
					& .label {
						font-size: 12px;
					}
					& .value {
						font-size: 14px;
					}
				}
			}
		}
	}
}

