@use "sass:math";

body {
  font-size: 14px;
}
/*=====================================COMMON CLASS=====================================*/
/* PADDING */
$class: "pd-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding: #{$i}px !important;
  }
}

/* PADDING TOP */
$class: "pd-t-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-top: #{$i}px !important;
  }
}

/* PADDING RIGHT */
$class: "pd-r-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-right: #{$i}px !important;
  }
}

/* PADDING BOTTOM */
$class: "pd-bt-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-bottom: #{$i}px !important;
  }
}

/* PADDING LEFT */
$class: "pd-l-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-left: #{$i}px !important;
  }
}

/* PADDING TOP BOTTOM */
$class: "py-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
}

/* PADDING LEFT RIGHT */
$class: "px-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
}

$class: "mg-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin: #{$i}px !important;
  }
}

$class: "mg-ipt-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin: #{$i}px !important;
  }
}

$class: "mg-t-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-top: #{$i}px !important;
  }
}

$class: "mg-r-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-right: #{$i}px !important;
  }
}

$class: "mg-bt-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

$class: "mg-l-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-left: #{$i}px !important;
  }
}

$class: "mx-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
}

$class: "my-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
}

// border width
$class: "border-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    border-width: #{$i}px !important;
  }
}

//font-size
$class: "font-size-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    font-size: #{$i}px !important;
  }
}

//line-height
$class: "line-h-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    line-height: #{$i}px !important;
  }
}

//top
$class: "top-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    top: #{$i}px !important;
  }
}

//top
$class: "bottom-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    bottom: #{$i}px !important;
  }
}

//left
$class: "bottom-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    bottom: #{$i}px !important;
  }
}

//left
$class: "right-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    right: #{$i}px !important;
  }
}

//height
$class: "h-";
@for $i from 0 through 100 {
  .#{$class}#{$i} {
    height: #{$i}px !important;
  }
}

.resize-none {
  resize: none;
}

.line-height-normal {
  line-height: normal;
}

.no-border {
  border: none;
}

.no-radius {
  border-radius: 0;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.flr {
  float: right;
}

.fll {
  float: left;
}

.clearfix {
  clear: both;
}

.text-yellow {
  color: #f39c12 !important;
}

.text-danger {
  color: #ff4d4f;
}

.text-blue {
  color: #2a7ee5;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.hide {
  display: none;
}

.text-al-r {
  text-align: right;
}

.text-al-l {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.bold-400 {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-medium {
  font-weight: 500;
}

.color-white {
  color: white;
}

.color-red {
  color: red;
}

.bg-red {
  background-color: red;
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: #efeeee;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.width-100pc {
  width: 100%;
}

.list-unstyled {
  margin: 0;
  padding: 0;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-20px {
  font-size: 20px;
}

.width-100px {
  width: 100px;
}

.width-150px {
  width: 150px;
}

.width-200px {
  width: 200px;
}

.width-270px {
  width: 270px;
}

.width-300px {
  width: 300px;
}

.table-responsive {
  overflow-x: auto;
  clear: both;
}

.v-text-top {
  vertical-align: text-top;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

// .d-inline-flex {
// 	display: inline-flex;
// }
.d-none {
  display: none !important;
}

.nowrap-css {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  display: inline-block;
}

.font-weight-word {
  font-weight: 600;
}

.breadcrumb-wrap-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.icon-quick-edit {
  font-size: 12px;
  color: #505050;
}

.icon-check-quick-edit {
  margin-left: 10px;
}

.blue-link {
  color: #1890ff;
}

.fix-css-tabs-nav {
  .ant-tabs-nav {
    padding-left: 20px;
  }
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}
//grid
.grid {
  display: grid;
}
.grid-items-end {
  justify-items: end;
}
// flex
.flex {
  display: flex;
}

// flex direction
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-hitem {
  flex: 1 1 0;
}

//justify content
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

//Justify Items
.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

//Align Content
.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

//Align Items
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

//Align Self
.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

//border color
.border {
  border: #b3ceed 2px solid;
}

.icon-active {
  background-color: #00ff00;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 10px;
}

.icon-inactive {
  background-color: red;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 10px;
}

//font-style

.italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.h-200 {
  height: 200px;
}

.text-maxW-250 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}

.text-maxW-300 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.h-100pc {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}

.negative-money {
  color: red;
}

.positive-money {
  color: green;
}

.transaction-code {
  font-size: 12px;
}

.default-money-total {
  font-size: 1.2em;
}

.first-letter-uppercase:first-letter {
  text-transform: uppercase;
}

.box-account-total-amount {
  font-size: 12px;
}

.empty-account {
  padding: 2em;
  color: red;
}

.font-12px {
  font-size: 12px;
}

.font-10px {
  font-size: 10px;
}

.h-scroll {
  overflow-x: auto;
}

.link--no-blue {
  color: inherit;
}

.has-comma {
  &:after {
    content: ",";
    display: inline;
    margin: 0 5px 0 0;
  }
}

.dirty-balance {
  background-color: #eb2f96;
  color: #fff;
  padding: 3px;
}

.form-item--no-label {
  margin-bottom: 10px;

  .ant-form-item-label {
    display: none;
  }
}

.img-cover {
  object-position: top center;
  object-fit: cover;
}

.transaction-remark {
  font-size: 0.7em;
  color: red;
  font-style: italic;
}

.d-plex {
  display: flex;
}

// Adding 17 Dec 2021 for app common

//font override
.app-font-md {
  // font-family: $font-app-base !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

$class: "rad-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    border-radius: #{$i}px !important;
  }
}

.flex-start {
  justify-content: flex-start;
}

.mg-l-imp-0 {
  margin-left: 0 !important;
}

$class: "gap-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    gap: #{$i}px !important;
  }
}

$class: "flex-basis-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-basis: #{$i}px !important;
  }
}

.flex-basis-190 {
  flex-basis: 190px;
}

$class: "flex-basis-pc-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-basis: percentage($number: math.div($i, 100));
  }
}

$class: "flex-shrink-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-shrink: #{$i}px;
  }
}

$class: "flex-grow-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-grow: #{$i};
  }
}

$class: "width-calc-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-basis: calc(100% - #{$i}px);
  }
}

.full-col {
  grid-column: 1 / -1;
}

.btn-xs {
  width: fit-content;
}

.white-nowrap {
  white-space: nowrap;
}

.mr-auto {
  float: right;
  margin-left: auto;
}

.image-auto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.width-72 {
  min-width: 71px !important;
  width: 71px !important;
}

.text-danger {
  color: #ff4559;
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #f65768;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.line-1 {
  width: 1px;
  background-color: #c7c7c7;
  height: 16px;
}

.overflow {
  overflow: auto;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-width-100pc {
  max-width: 100%;
}

.max-width-500 {
  max-width: 500px;
}

$class: "truncate-multiline-";
@for $i from 0 through 100 {
  .#{$class}#{$i} {
    -webkit-line-clamp: $i;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal !important;
  }
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.w-500 {
  width: 500px;
}

.w-400 {
  width: 400px;
}

.unset-text-wrap {
  white-space: unset;
}

.pointer {
  cursor: pointer;
}

.badge-gray-yellow {
  .ant-badge-count {
    background-color: rgb(209 209 209 / 60%);
  }

  .ant-scroll-number-only-unit {
    color: #1e1e1e;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.break-word {
  word-wrap: initial;
}
.ant-table {
  color: $color-app-base;
}
.ant-pagination {
  text-align: right;
}
.ant-pagination {
  text-align: right;
}
.ant-pagination-disabled {
  .ant-pagination-item-link {
    background-color: $color-app-gray3;
  }
}
.ant-pagination-total-text {
  color: $color-app-base;
}

.flex-shrink-none {
  flex-shrink: 0;
}

.flex-basis-80pc {
  flex-basis: calc((100% - 12px) * 8 / 10);
}
.flex-basis-20pc {
  flex-basis: calc((100% - 12px) * 2 / 10);
}
$class: "truncate-multiline-";
@for $i from 0 through 5 {
  .#{$class}#{$i} {
    -webkit-line-clamp: $i;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.items-baseline {
  align-items: baseline;
}
.width100pc {
  width: 100%;
}

.flex-basis-100pc {
  flex-basis: 100%;
}
.flex-basis-40pc {
  flex-basis: 40%;
}
.flex-basis-50pc {
  flex-basis: 50%;
}
.flex-basis-60pc {
  flex-basis: 60%;
}

.flex-grow-0 {
  flex-grow: 0;
}
.white-text {
  color: white;
}
.w-14 {
  width: 14px;
}

.ant-typography-edit-content {
  left: 0;
}
//color

.txt-color-white {
  color: $txt-color-white;
}

.txt-color-black {
  color: #1a1a1a;
}

// background
.bg-orange {
  background-color: #ffa634;
}

// box-model
.h-fit-content {
  height: fit-content;
}
.h-auto {
  height: auto;
}
.whitespace-nor-impt {
  white-space: normal !important;
}

.border-divider {
  border-left: 1px solid $color-app-gray3;
}

.width-260 {
  width: 260px;
}

//modal
.confirm-status {
  .ant-modal-content {
    width: fit-content;
    // width: 600px;
    & .ant-modal-body {
      padding: 12px;
    }
    .ant-btn {
      padding: 4px 16px;
      border-radius: 4px;
      &:hover {
        box-shadow: 0 0 0 3px $color-app-gray5;
        border-color: $color-app-gray5;
        & > span {
          color: $color-app-base;
        }
      }
      &.ant-btn-primary:hover {
        box-shadow: 0 0 0 3px $color-app-violet-light1;
        & > span {
          color: white;
        }
      }
    }
    & .ant-modal-close {
      top: -4px;
      color: $color-app-base;
    }
  }
  .ant-modal-confirm-btns {
    margin-top: 12px;
  }
}

.services {
  margin-right: 2px;
  display: flex;
  align-items: center;
  line-height: 22px;
  white-space: nowrap;
  flex-wrap: wrap;
  font-size: app-font-sizes(reg);
  &-pending {
    color: #989795;
  }
  &-approve {
    color: #489e26;
  }
  &-refuse {
    color: #d0302c;
  }
  &-reject {
    color: #d0302c;
    text-decoration: line-through;
  }
}

.box-main {
  background-color: white;
  box-shadow: 0px 2px 14px #e6e6e6;
  padding: 12px;
}
.min100vh {
  min-height: 100vh;
}
.color-app-black {
  background-color: $color-app-black;
}

.invoice-dropdown__status {
  width: fit-content !important;
}

.text-color-primary {
  color: #008df9;
}

.bg-color-app-green {
  background-color: $color-app-green;
}

.bg {
  &-primary {
    background-color: $primary-color;
  }
  &-pink {
    background-color: $color-app-pink;
  }
}

.flex-auto {
  flex: auto;
}

.z-index-998 {
  z-index: 998;
}

.txt-capitalize {
  text-transform: capitalize;
}

.bottom-list-item-skeleton {
  border-bottom: 1px solid #ebebeb;
  padding: 12px;
}

.orderDetail {
  &-collapse,
  &-expand {
    position: absolute;
    right: 12px;
    cursor: pointer;
    color: #1a1a1a;
    font-size: 12px;
  }
  &-collapse {
    top: 16px;
  }
  &-expand {
    bottom: 4px;
  }
}

.box-order {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 14px #e6e6e6;
}

.position-re {
  position: relative;
}

.bg-color-badge {
  background-color: #cd5e77;
}

.bg-color-view {
  background-color: #fff6f6;
}
.bg-table-color-pink {
  background-color: #ffbebe !important;
}
.txt-color-view {
  color: #cd5e77;
}
.txt-color-gray {
  color: #707070;
}

.txt-color-gray1 {
  color: #7d7e7e;
}
.txt-color-gray2 {
  color: #b1b1b1;
}

.row-dark,
.row-light {
  & .ant-table-tbody {
    & > tr > td {
      &:first-child {
        border-top-left-radius: 0px;
      }
      &:last-child {
        border-top-right-radius: 0px;
      }
    }
  }
}

.row-light {
  background-color: #f9f9f9;
}
.row-light-provider {
  background-color: #fafafa;
}
.row-items {
  .table-improved
    .ant-table-content
    .ant-table-tbody
    > tr.ant-table-row:not(.data-row_extended):hover
    > td {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}

.ant-typography {
  margin-bottom: 0 !important;
}

.txt-color-red {
  color: #ff4559;
}
.bg-color-pink {
  background-color: #fff0f0;
}
.color-gray {
  color: #ebebeb !important;
}
.txt-color-green {
  color: $color-app-green;
}

.txt-color-gray3 {
  color: #5a5a5a;
}

.width-50 {
  width: 95%;
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-99.7%) translateY(-50%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(99.7%) translateX(-50%);
}
.txt-linethrough {
  text-decoration: line-through;
}
.m22-select {
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 8px;
    height: 44px;
  }
}

.ant-divider.ant-divider-horizontal.ant-divider-dashed {
  height: 1px;
  background: linear-gradient(to right, #ebebeb, 6px, transparent 6px);
  background-size: 12px;
  border: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 44px;
}

.white-space-pre-wrap-impt {
  white-space: pre-wrap !important;
}