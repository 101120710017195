.provider-selection {
  & .ant-select-selector {
    border-radius: 4px !important;
  }
}

.phone {
  position: relative;
  .messages-validate {
    position: absolute;
    bottom: -25px;
    left: 0;
  }
}

@include xs {
  .customer-detail {
    & .box-info {
      flex-direction: column;
      & .avatar {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}

.text-aria-deposit {
  font-size: 14px !important;
  border-radius: 8px !important;
}

.transaction-history-card {
  max-width: calc(100vw - 36px);

  @media screen and (min-width: 767.99px) {
    max-width: calc(100vw - 52px);
  }

  @media screen and (min-width: 1278.99px) {
    max-width: 100%;
  }

  .ant-table-body {
    // overflow: auto auto !important;
    // max-width: 1050px;
  }
}