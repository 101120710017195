.shipment {
	margin: 4px;
	&-heading {
		display: flex;
		background-color: white;
		padding: 12px 15px;
		margin-bottom: 12px;
		box-shadow: 0px 2px 14px $box-shadow-color1;
		border-radius: 6px;
		&__basic {
			margin-right: 64px;
			&-image {
				height: 100%;
				width: 56px;
			}
			&-text {
				&--code {
					.ant-select:not(.ant-select-customize-input) .ant-select-selector {
						background-color: #3eb2f0;
						border-radius: 25px;
						height: 28px;
						align-items: center;
						color: white;
						border: none;
						box-shadow: none !important;
						& .ant-select-selection-item {
							font-weight: 500;
							font-size: 14px;
							line-height: 22px;
						}
					}
					.ant-select-single.ant-select-open .ant-select-selection-item {
						color: white;
					}
					.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
						border: none;
						box-shadow: none;
					}
				}
			}
		}
		&-logs:hover {
			color: $color-app-violet;
		}
	}
	& .item {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		&-finance {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		& .name {
			width: 100%;
		}

		& .text {
			flex-basis: 100pc;
			&-sub {
				color: $color-app-gray1;
			}
		}
		& .ant-typography {
			margin-bottom: 0;
			&.editing {
				left: 0 !important;
				& > textarea {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}
	&-milestones {
		// flex-basis: 100%;
		padding: 24px;
		display: flex;
		flex-direction: column;
		// padding: 12px 12px 0 12px;
		box-shadow: 0px 2px 14px $box-shadow-color1;
		gap: 8px;
		background-color: white;
		border-radius: 6px;
		.ant-steps-item-active {
			.ant-steps-item-tail {
				&::after {
					background-color: $color-app-violet !important;
				}
			}
		}
		.ant-steps-item-wait {
			.ant-steps-item-tail {
				&::after {
					background-color: #bdbdbd !important;
				}
			}
		}

		& .ant-steps-item-icon {
			width: 10px !important;
			height: 10px !important;
			.ant-steps-icon {
				top: -10.5px !important;
			}
		}
		.ant-steps-item-content {
			text-align: left !important;
		}

		.ant-steps-item-tail {
			top: 1.5px !important;
			&::after {
				margin-left: 13.5px !important;
			}
		}

		.ant-steps-item-title {
			line-height: 20px !important;
			font-size: 12px !important;
			font-family: 'Roboto', sans-serif !important;
		}
		.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
			color: #bdbdbd;
		}
		.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
			color: #bdbdbd;
		}
	}
	.fees {
		&-calculate,
		&-summary {
			display: flex;
			flex-direction: column;
			gap: 4px;
			// background-color: $color-app-violet-light2;
			background-color: #fff7f7;
			font-size: app-font-sizes(reg);
			line-height: 22px;
			border-radius: 4px;
			& .ant-divider-horizontal {
				margin: 4px 0;
				border-top: 1px dashed #c0c0c0;
			}
		}
		&-calculate {
			border-radius: 6px 6px 0px 0px;
			padding: 16px 16px 16px 21px;
		}
		&-summary {
			padding: 16px 16px 16px 21px;
			margin-top: 8px;
		}
	}
	&-content {
		display: flex;
		// background-color: white;
		&__info {
			&-finance {
				position: relative;
				border-radius: 6px;
				background-color: white;
			}
		}
		&__text {
			display: flex;
			flex-direction: column;
			flex-basis: calc((100% - 10px) * 8 / 10);
			flex-wrap: wrap;
			justify-content: flex-start;
			&-info,
			&-finance {
				// box-shadow: 0px 2px 14px $box-shadow-color1;
				display: flex;
				flex-direction: column;
				gap: 16px;
			}
			&-info {
				display: flex;
				flex-direction: column;
				flex-basis: 50%;
				margin-right: 10px;
				& .ant-divider-vertical {
					margin: 0 20px;
				}
				& > h3 {
					font-weight: 500;
					font-size: app-font-sizes(md);
					line-height: 24px;
					margin: 0;
				}
			}
			&-finance {
				flex-basis: 50%;
				& > h2 {
					font-size: app-font-sizes(md);
					line-height: 24px;
				}
			}
		}
		&__track {
			background-color: white;
			padding: 12px 12px 0 12px;
			box-shadow: 0px 2px 14px $box-shadow-color1;
			height: fit-content;
			flex-basis: calc((100% - 10px) * 2 / 10);
		}
	}
	&-tabs {
		border-radius: 6px;
		box-shadow: 0px 2px 14px $box-shadow-color1;
		margin-top: 10px;
		padding: 12px;
		background-color: white;
		& .ant-tabs-nav {
			margin-bottom: 10px;
			margin-left: 12px;
			& .ant-tabs-tab {
				padding-top: 0;
				padding-bottom: 0;
				&:not(:first-of-type) {
					// margin-left: 12px;
				}
			}
		}

		& .item-link {
			font-size: app-font-sizes(reg);
			line-height: 22px;
			&:hover {
				color: $color-app-violet;
			}
		}
	}

	&-dropdown__status {
		// padding: 0;
		border-radius: 0 0 2px 2px;
		& .ant-select-item-option-content {
			font-size: app-font-sizes(reg);
			line-height: 22px;
		}
	}

	.shipmentDetail-table {
		.ant-table table {
			width: auto !important;
		}
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			display: none;
		}
		.ant-table-container table > thead > tr:first-child th:first-child {
			border-top-left-radius: 6px;
			padding-left: 16px;
		}
		.ant-table-container table > thead > tr:first-child th:last-child {
			border-top-right-radius: 6px;
			padding-right: 16px;
		}
		.ant-table-thead > tr > th {
			border-bottom: none;
			background: #f6f6f6;
		}
		.ant-table-tbody > tr > td {
			border-bottom: none;
		}
		.ant-table-tbody > tr > td:first-child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		.ant-table-tbody > tr > td:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
		.ant-table-tbody > tr.ant-table-row:hover > td,
		.ant-table-tbody > tr > td.ant-table-cell-row-hover {
			background-color: #fff7f7;
		}
		.row-dark {
			background-color: #ffffff;
		}
		.row-light {
			background-color: #fafafa;
		}
		.ant-table-content .ant-table-tbody::before {
			content: '-';
			display: block;
			line-height: 1em;
			color: transparent;
		}
	}
	&-logs {
		.ant-modal-root .ant-modal-header {
			border-radius: 4px 4px 0 0;
			padding: 16px 24px 0 16px;
			border-bottom: 0;
		}
	}
}

@media screen and (max-width: 1024px) {
	.shipment {
		&-heading,
		&-milestones,
		&-content__info,
		&-content__text-info,
		&-content__text-finance {
			max-width: calc(100vw);
		}
		&-tabs {
			width: calc(100vw - 30px);
		}
		&-milestones {
			flex-basis: 100%;
			.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
				color: #bdbdbd;
			}
		}
		&-content {
			flex-direction: column-reverse;
			&__info {
				flex-basis: 100%;
				margin-bottom: 12px;
				&-finance {
					// flex-wrap: wrap;
					width: calc(100vw - 30px);
					// padding-bottom: 40px;
				}
			}
			&__text-info {
				flex-basis: 100%;
				// padding-bottom: 40px;
				// margin-bottom: 12px;
				// margin-right: 0;
			}
			&__text-finance {
				flex-basis: 100%;
			}
		}
		&-milestones {
			.ant-steps {
				// overflow-x: scroll;
				gap: 4px;
			}
			.ant-steps-item-tail {
				top: 66.5px !important;
				margin-left: 0;
				&::after {
					// margin-left: 0 !important;
				}
			}

			& .ant-steps-item {
				& .ant-steps-item-container {
					display: flex;
					flex-direction: column-reverse;
					justify-content: space-between;
					gap: 2px;
					height: 100%;
					& .ant-steps-item-description {
						margin-bottom: 8px;
					}
				}
			}

			.ant-steps-item-active .ant-steps-item-tail::after {
				background-color: #cd5e77 !important;
				// border: 1px solid #c7c7c7;
				height: 2px;
			}
		}
	}
	.package-timeline .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail,
	.package-timeline .ant-steps-item.ant-steps-item-wait .ant-steps-item-tail {
		margin: 0 auto !important;
		bottom: 10px !important;
		top: unset !important;
	}
	.package-timeline .ant-steps-item.ant-steps-item-finish .ant-steps-item-title span {
		color: #bdbdbd;
	}
	.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
		color: #bdbdbd;
	}
	.tabs-package-timeline {
		.package-timeline .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail,
		.package-timeline .ant-steps-item.ant-steps-item-wait .ant-steps-item-tail {
			margin: 0 auto !important;
			bottom: 0 !important;
			top: unset !important;
		}
	}
}
.shipment {
	&-expand {
		position: absolute;
		left: 45%;
		cursor: pointer;
		color: #cd5e77;
		font-size: 12px;
	}
	&-collapse {
		position: absolute;
		color: #cd5e77;
		right: 26px;
		top: 22px;
		cursor: pointer;
	}
	&-expand {
		bottom: 16px;
	}
}

@media screen and (max-width: 767px) {
	.shipment {
		&-tabs,
		&-milestones,
		&-content__info,
		&-content__text-info,
		&-content__text-finance {
			max-width: calc(100vw);
			overflow-y: auto;
		}
		&-heading {
			display: grid;
		}

		&-milestones {
			flex-basis: 100%;
			padding: 16px;
			.ant-steps {
				overflow-y: hidden;
				overflow-x: scroll;
			}
		}
		&-content {
			flex-direction: column-reverse;
			&__info {
				flex-basis: 100%;
				margin-bottom: 12px;
				overflow: hidden;
				&-finance {
					display: grid;
				}
			}
			&__text-info {
				flex-basis: 100%;
				padding: 6px;
				// margin-bottom: 12px;
				// margin-right: 0;
			}
			&__text-finance {
				flex-basis: 100%;
				padding: 12px 6px;
				padding-top: 40px;
			}
		}
		&-milestones {
			.ant-steps {
				// overflow-x: scroll;
				// gap: 4px;
			}
			.ant-steps-item-tail {
				top: 1.5px !important;
				margin-left: 0;
				&::after {
					// margin-left: 0 !important;
				}
			}

			& .ant-steps-item {
				& .ant-steps-item-container {
					display: flex;
					flex-direction: column-reverse;
					justify-content: space-between;
					gap: 2px;
					height: 100%;
					& .ant-steps-item-description {
						margin-bottom: 8px;
					}
				}
			}

			.ant-steps-item-active .ant-steps-item-tail::after {
				background-color: #cd5e77 !important;
				// border: 1px solid #c7c7c7;
				// height: 2px;
			}

			.package-timeline .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail,
			.ant-steps-item.ant-steps-item-active .ant-steps-item-tail {
				bottom: 11px !important;
				top: unset !important;
			}
		}
		.fees-calculate {
			border-radius: 6px 6px 0px 0px;
			padding: 16px 16px 16px 16px;
		}
	}
	.shipmentDetail-table .ant-table table {
		width: max-content !important;
	}
	.shipment {
		&-expand {
			position: absolute;
			left: 92%;
			top: 22px !important;
			cursor: pointer;
			color: #cd5e77;
			font-size: 12px;
		}
		&-collapse {
			position: absolute;
			color: #cd5e77;
			left: 92%;
			top: 22px;
			cursor: pointer;
		}
	}
	.package-timeline .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail,
	.package-timeline .ant-steps-item.ant-steps-item-wait .ant-steps-item-tail {
		margin: 0px auto !important;
		bottom: 0 !important;
		top: unset !important;
	}

	// .shipmentDetail-table {
	.ant-table table {
		// width: max-content !important;
	}
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
			[colspan]
		)::before {
		display: none;
	}
	.ant-table-container table > thead > tr:first-child th:first-child {
		border-top-left-radius: 6px;
		padding-left: 16px;
	}
	.ant-table-container table > thead > tr:first-child th:last-child {
		border-top-right-radius: 6px;
		padding-right: 16px;
	}
	.ant-table-thead > tr > th {
		border-bottom: none;
		background: #f6f6f6;
	}
	.ant-table-tbody > tr > td {
		border-bottom: none;
	}
	.ant-table-tbody > tr > td:first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	.ant-table-tbody > tr > td:last-child {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	.ant-table-tbody > tr.ant-table-row:hover > td,
	.ant-table-tbody > tr > td.ant-table-cell-row-hover {
		background-color: #fff7f7;
	}
	.row-dark {
		background-color: #ffffff;
	}
	.row-light {
		background-color: #fafafa;
	}
	.ant-table-content .ant-table-tbody::before {
		content: '-';
		display: block;
		line-height: 1em;
		color: transparent;
	}
	// }
}
@include xs {
	.shipment-heading {
		&__basic {
			flex-grow: 1;
			margin: 0;
			&-text {
				flex-direction: row;
				flex-basis: 100%;
				flex-wrap: wrap;
				&--code {
					gap: 10px;
					flex-wrap: wrap;
					margin-bottom: 6px;
					flex-basis: 100%;
					& .ant-typography {
						margin-right: 0;
						font-size: 14px;
						line-height: 22px;
					}
				}
			}
			&-merchant {
				flex-basis: 100%;
				flex-direction: row;
				flex-wrap: wrap;
				&--cash,
				&--name {
					& .label {
						font-size: 12px;
					}
					& .value {
						font-size: 14px;
					}
				}
			}
		}
	}
}

// .shipment-timeline {
// 	.ant-steps-item {
// 		// padding-left: 2px;
// 		&.ant-steps-item-finish,
// 		&.ant-steps-item-wait {
// 			// .ant-steps-item-tail {
// 			// 	margin: 0 auto !important;
// 			// 	bottom: 1px !important;
// 			// 	top: unset !important;
// 			// 	&::after {
// 			// 		height: 0 !important;
// 			// 		margin-left: 15px !important;
// 			// 	}
// 			// }
// 			.ant-steps-item-container {
// 				display: block;
// 				height: 100%;
// 				margin-top: 5px;
// 				margin: -l;
// 				margin-left: 5px;
// 				.ant-steps-item-content {
// 					// margin: 0;
// 					.ant-steps-item-title {
// 						display: flex;
// 						align-items: flex-start;
// 					}
// 				}
// 			}
// 		}
// 		&.ant-steps-item-finish {
// 			.ant-steps-item-title {
// 				span {
// 					color: #1a1a1a;
// 				}
// 			}

// 			i {
// 				color: #c7c7c7;
// 			}
// 			.ant-steps-item-tail {
// 				&::after {
// 					border: 1px solid #c7c7c7;
// 					background-color: #c7c7c7;
// 				}
// 			}

// 			&.tail-none {
// 				.ant-steps-item-tail {
// 					display: none;
// 				}
// 				&.last-tail {
// 					i {
// 						color: $color-app-violet;
// 					}
// 				}
// 			}
// 		}
// 		&.ant-steps-item-wait {
// 			&.tail-none {
// 				.ant-steps-item-title > span,
// 				.description > span {
// 					color: #bdbdbd;
// 				}
// 				.ant-steps-item-tail {
// 					display: none;
// 				}
// 				.ant-steps-item-icon {
// 					visibility: hidden;
// 				}
// 			}
// 			.ant-steps-item-tail {
// 				&::after {
// 					// border: 1px solid $color-app-violet;
// 					border: 1px solid #c7c7c7;
// 				}
// 			}
// 		}
// 	}
// 	.ant-steps-item-content {
// 		width: 100% !important;
// 		text-align: left !important;
// 		margin-top: 15px;
// 	}
// 	.ant-steps-item-icon {
// 		margin: 0 !important;
// 		width: 10px !important;
// 		height: 10px !important;
// 	}

// 	&.bd-bt-none {
// 		& .td-table-cell {
// 			border-bottom: none;
// 		}
// 	}
// }

