.m22-input {
	border-radius: 8px;
	&.ant-input-lg {
		line-height: 25px;
	}

	&.ant-input-affix-wrapper {
		height: 44px;
		& .ant-input-suffix {
			// color: color(color-gray-tertiary-plus);
		}
	}
}
.provider {
	&-textarea {
		border-radius: 8px;
	}
	&-item {
		background-color: #ffffff;
		border-radius: 6px;
		padding: 16px 14px;
		margin-top: 12px;
	}
	&-tooltip {
		&.ant-tooltip-placement-top .ant-tooltip-arrow {

		}
	}
}

.modalAddProvider {
	.ant-modal-header {
		border-radius: 6px;
		padding: 16px 24px 0 16px;
		border-bottom: 0;
	}
	.ant-modal-footer {
		padding: 0px 16px 16px 16px;
		text-align: right;
		background: transparent;
		border-radius: 0 0 2px 2px;
		border: none;
	}
	.ant-modal-body {
		padding: 16px;
	}
}
.float-label {
	position: relative;
	width: 100%;
	& .label,
	& .label-range {
		z-index: 1;
		position: absolute;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		top: 50%;
		transform: translate(0, -50%);
		left: 16px;
		transition: 0.2s ease all;
		pointer-events: none;
		padding: 0 3px;
		&:hover {
			background-color: red;
		}
		&-float {
			top: -12px !important;
			transform: translate(0, 0);
			background-color: white;
			// color: #cd5e77;
			padding: 0 3px;
			font-size: 12px;
			z-index: 2;
			&.area-label {
				top: -12px;
			}
		}

		&.area-label {
			top: 19.5px;
		}
	}

	&.dark {
		& .label,
		& .label-range {
			&-float {
				background-color: #1f2328;
				color: color(color-gray-quaternary-plus);
			}
		}

		& .ant-select-arrow {
			color: color(color-gray-quaternary-plus) !important;
		}
	}

	& .label-range {
		left: calc(50% + 12px);
	}
}

.ant-table-expanded-row-fixed {
    width: -webkit-fill-available !important;
}
